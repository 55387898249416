
<template>
  <div>
    <b-card no-body class="m-0">
      <b-row>
        <b-col md="6" class="d-flex mt-1">
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :clearable="false"
            v-model="per_page"
            class="per-page-selector d-inline-block ml-2 w-25"
            :options="['5', '10', '20', '50', '100']"
            @input="changePagination()"
          />
          <!-- Search -->
        </b-col>
        <b-col class="d-flex d-flex justify-content-end">
          <b-badge variant="light-primary" style="height:30px;font-size:20px" @click="handleSupplementRequest()" class="text-center titleName font-weight-bold cursor-pointer my-1 mr-2"
            >Supplement Request</b-badge>
        </b-col>
      </b-row>
      <div class="m-2">
        <section id="card-images" class="container1">
          <b-row
            md="12"
            style="align-item: center; justify-content: center; display: flex"
          >
            <b-table
              :busy="isLoading"
              ref="refUserListTable"
              class="position-relative"
              :items="staticUsers"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              empty-text="No matching records found"
              :sort-desc.sync="isSortDirDesc"
              @sort-changed="onSortChanged"

            >
              <template #table-busy>
                <div class="text-center text-secondary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
              <template #cell(id)="data">
                <span
                  class="text-primary font-weight-bold"
                  style="font-size: 15px"
                >
                  {{ data.index + 1 }}
                </span>
              </template>

              <template #cell(name)="data">
        <div class="d-flex align-items-center">
        <span class="mr-1">
            <template v-if="data.item && data.item.image">
                <b-img
                    height="60px"
                    width="60px"
                    class="mr-1 titleName"
                    :src="data.item.image"
                    alt="Image"
                ></b-img>
            </template>
            <template v-else>
              <span>
                <img style="height:40px;width:40px" src="../../../../../public/no-image.png" alt="">
              </span>
            </template>
        </span>
        <span class="mr-1">
            <span class="mr-1">
                {{ data.item.name }}
            </span>
        </span>
    </div>
</template>
              <template #cell(supplementbrand)="data">
                <span class="mr-1">
                  {{ data.item.supplementbrand }}
                </span>
              </template>
              
              <template #cell(Action)="data">
                <feather-icon
                  icon="EditIcon"
                  size="22"
                  class="cursor-pointer mr-1"
                  @click="handleEditForm(data.item)"
                />
                <feather-icon
                  icon="Trash2Icon"
                  size="22"
                  class="m-1"
                  @click="removeRow(data.item.id)"
                  style="cursor: pointer"
                />
                <feather-icon
                  icon="EyeIcon"
                  size="22"
                  class="m-1"
                  @click="openData(data.item)"
                  style="cursor: pointer"
                />
              </template>
            </b-table>
            <div class="w-100 d-flex justify-content-between p-1">
              <!-- Pagination -->
              <div>
                <span class="text-nowrap">
                  Showing {{ paginationStart }} to {{ paginationEnd }} of
                  {{ paginationTotal }}</span
                >
              </div>
              <b-pagination
                :total-rows="paginationTotal"
                :per-page="per_page"
                v-model="paginationValue"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="changePagination()"
              >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
              </b-pagination>
            </div>
            <b-col class="col-md-12 d-flex d-flex justify-content-end">
              <b-button variant="primary" @click="Supplement" class="mt-2"
                >Add Supplement</b-button
              >
            </b-col>

            <b-modal
              hide-footer
              top
              no-close-on-backdrop
              size="md"
              v-model="openModel"
            >
              <view-supplement :selectedItem="selectedItem"/>
            </b-modal>
          </b-row>
        </section>
        <div class="mt-5" v-if="addSupplement">
          <Form :selectedItem='selectedItem' :ifEdit="ifEdit" :handleCloseForm="handleCloseForm" :getSuppleData="getSuppleData" />
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCardText,
  BCardTitle,
  BPagination,
  BButton,
  BCard,
  BForm,
  BTable,
  BFormFile,
  BFormInput,
  BFormGroup,
  BBadge,
  BSpinner,
  BImg,
  BFormTextarea
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from "axios";
import _ from "lodash";
import ViewSupplement from './View.vue';
import Form from './Form.vue'

export default {
  components: {
    BFormTextarea,
    BRow,
    BCol,
    BCardText,
    BImg,
    BCardTitle,
    BButton,
    BPagination,
    BCard,
    BForm,
    BTable,
    vSelect,
    BFormFile,
    BFormInput,
    BFormGroup,
    BBadge,
    ValidationProvider,
    BSpinner,
    ValidationObserver,
    ViewSupplement,
    Form
  },
  data() {
    return {
      flag: false,
      tableColumns: [
        { key: "name", label: "Supplement Name", sortable: true },
        { key: "supplementbrand", label: "Supplement Brand", sortable: true },
        { key: "Action", label: "Action" },
      ],
      isSortDirDesc: true,
      addSupplement: false,
      required: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      selectedId: null,
      sortBy: "",
      orderBy: true ? "ASC" : "DESC",
      SupplementName: "",
      clickedRowData: null,
      SupplementBrandValue: "",
      DescriptionValue: "",
      CommentValue: "",
      isSideDataLoading: false,
      SupplementNameValue: "",
      openModel: false,
      staticUsers: [],
      id: 0,
      isLoading: false,
      selectedItem: {},
      ifEdit: false,
      paginationValue: 1,
      paginationTotal: 0,
      per_page: 10,
      paginationStart: 0,
      paginationEnd: 0,
      image: "",
      columnFilters:{},
      params:{}
    };
  },
  mounted() {
    this.getSuppleData();
  },
  methods: {
    handleCloseForm(){
      this.ifEdit=false;
      this.selectedItem={};
      this.addSupplement=false
    },
    
    onSortChanged(e) {
      this.sortBy = e.sortBy;
      this.orderBy = e.sortDesc ? "DESC" : "ASC";
      this.getSuppleData();
    },
    filterTable :_.debounce(function (){
      this.params={}
      for(let key in this.columnFilters){
        if(this.columnFilters[key] && typeof this.columnFilters[key] == 'object'){
          this.params[key] = this.columnFilters[key].map((inner) =>inner.id).join(',')
        }
        else{
          this.params[key] = this.columnFilters[key]
        }
      }
      this.params.page = this.paginationValue
      this.getSuppleData()
    },1000),
    changePagination() {
      this.getSuppleData();
    },
    async handleEditForm(item) {
      this.addSupplement = true;
      this.ifEdit = true;
      this.selectedItem = item
    },
    openData(data) {
      this.openModel = true;
      this.selectedItem = data;
    },
    async removeRow(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then(async (result) => {
          if (result.value) {
            await axios({
              method: "Delete",
              url: `${this.baseApi}supplement/delete/${id}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
            })
              .then(() => {
                const index = this.staticUsers.findIndex(
                  (item) => item.id === id
                );
                if (index !== -1) {
                  this.staticUsers.splice(index, 1);
                  this.$swal({
                    title: "Deleted",
                    text: "Supplement deleted successfully",
                    icon: "success",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                  this.getSuppleData();
                  this.$forceUpdate();
                }
              })
              .catch((error) => {
                console.error("Error deleting user:", error);
              });
          }
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {});
    },

    handleSupplementRequest(){
      this.$router.push({ path: "/supplement-approval" });
    },

    async getSuppleData() {
      this.isLoading = true;
      let params = {};
      params.page = this.paginationValue;
      params.per_page = this.per_page;
      if (this.search) {
        params.search = this.search.id ? this.search.id : this.search;
        params.type = this.searchTerm.value;
      }
      if (this.sortBy) {
        params.sortBy = this.sortBy;
        params.orderBy = this.orderBy;
      }
      await axios({
        method: "GET",
        url: `${this.baseApi}supplement/get`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params:params
      })
        .then((json) => {
          this.staticUsers = json?.data.supplement;
          this.paginationTotal = json?.data.pagination.total;
          this.paginationStart = json?.data.pagination.current_page;
          this.paginationEnd = json?.data.pagination.per_page;
          this.isLoading = false;
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.isLoading = false;
        });
    },
    Supplement() {
      // this.selectedId = id;
      this.ifEdit = false
      this.addSupplement = !this.addSupplement;
      this.SupplementBrandValue = "";
      this.SupplementNameValue = "";
      this.DescriptionValue = "";
      this.CommentValue = "";
      setTimeout(() => {
                const formElement = this.$refs.myForm;
                if (formElement) {
                formElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',    
                    });
                }
            }, 100);
    },
  },
};
</script>
<style lang="scss">
.text-danger {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
.suppli-img{
  max-height: 300px;
  max-width: 300px
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

 
